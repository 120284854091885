import React from 'react'
import Layout from 'src/layouts/BaseLayout'
import { Wrapper } from '../../politica-de-seguranca/style'
import pageContext from './pageContext.json'

import Hero from 'src/pages/politica-de-seguranca/sections/hero/_index'
import ProtegerInformacao from 'src/pages/politica-de-seguranca/sections/protegerInformacao/_index'
import Principios from 'src/pages/politica-de-seguranca/sections/principios/_index'
import Abrangencias from 'src/pages/politica-de-seguranca/sections/abrangencias/_index'
import Responsabilidades from 'src/pages/politica-de-seguranca/sections/responsabilidades/_index'
import Conformidade from 'src/pages/politica-de-seguranca/sections/conformidade/_index'

const PrivacyPolicy = () => {
  const lang = 'en'

  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero lang={lang} />
        <ProtegerInformacao lang={lang} />
        <Principios lang={lang} />
        <Abrangencias lang={lang} />
        <Responsabilidades lang={lang} />
        <Conformidade lang={lang} />
      </Layout>
    </Wrapper>
  )
}

export default PrivacyPolicy
